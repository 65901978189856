import countries from '../data/countries';
import './Header/Header.css';
import ImageOpt from './ImageOpt';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, graphql, navigate, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker/dist/react-datepicker.min.js';

const CURRENCIES = {
  USD: 'USD',
  ZAR: 'ZAR',
  GBP: 'GBP',
};

const budgetOptions = {
  USD: {
    inputId: 'input_63',
    options: [
      {
        label: '<b>$5,000 - $7,500</b> per person per week',
        value: '$5,000 - $7,500 per person',
      },
      {
        label: '<b>$7,500 - $10,000</b> per person per week',
        value: '$7,500 - $10,000 per person',
      },
      {
        label: '<b>Over $10,000</b> per person per week',
        value: 'Over $10,000 per person',
      },
    ],
  },
  ZAR: {
    inputId: 'input_68',
    options: [
      {
        label: '<b>R 100,000 - R150,000</b> per person per week',
        value: 'R 100,000 - R150,000 per person',
      },
      {
        label: '<b>R 150,000 - R200,000</b> per person per week',
        value: 'R 150,000 - R200,000 per person',
      },
      {
        label: '<b>Over R200,000</b> per person per week',
        value: 'Over R200,000 per person',
      },
    ],
  },
  GBP: {
    inputId: 'input_69',
    options: [
      {
        label: '<b>£4000 - £6000</b> per person per week',
        value: '£4000 - £6000 per person',
      },
      {
        label: '<b>£6000 - £8000</b> per person per week',
        value: '£6000 - £8000 per person',
      },
      {
        label: '<b>Over £8000</b> per person per week',
        value: 'Over £8000 per person',
      },
    ],
  },
};

const GuestEnquiry = ({ module }) => {
  const [stepForm, setStepForm] = useState(1);
  const [dataObject, setDataObject] = useState(null);
  const [year, setYear] = useState('');
  const [selectedBudgetCurrency, setSelectedBudgetCurrency] = useState(
    CURRENCIES.USD
  );

  useEffect(() => {
    const attributionData = sessionStorage.getItem('locationState');

    if (attributionData) {
      try {
        const dataPage = JSON.parse(attributionData);
        setDataObject(dataPage);
        setForm({
          ...form,
          input_72: dataPage.prevPage || '',
          input_74: dataPage.referrer || '',
          input_75: dataPage.utmCampaign || '',
          input_76: dataPage.utmContent || '',
          input_77: dataPage.utmMedium || '',
          input_78: dataPage.utmSource || '',
          input_79: dataPage.utmTerm || '',
        });
      } catch (error) {
        console.error('Error parsing attributionData JSON: ', error);
      }
    }
  }, []);

  const [form, setForm] = useState({
    // Step 1
    input_11: 'I know my exact dates',

    // Attribution
    input_72: '',
    input_74: '',
    input_75: '',
    input_76: '',
    input_77: '',
    input_78: '',
    input_79: '',

    // Step 2
    input_80: '',
    input_82: '',
    input_47: '',
    input_70: '',

    // Step 3
    input_54: '',
    input_54_1: '',
    input_54_2: '',
    input_54_3: '',
    input_54_4: '',
    input_54_5: '',
    input_54_6: '',
    input_54_7: '',
    input_54_8: '',
    input_54_9: '',
    input_54_10: '',
    input_54_11: '',
    input_54_12: '',
    input_54_13: '',
    input_54_14: '',
    input_54_15: '',
    input_54_16: '',
    input_54_17: '',

    // Step 4
    input_60: '',
    input_61: '',

    // Step 5
    input_67: 'USD',
    input_63: '',
    input_68: '',
    input_69: '',

    // Step 6
    input_65: '',
    input_26: '',
    input_25: '',
    input_28: '',
    input_27: '',
    input_43: '',
    input_44: '',
    input_81_1: '',
    input_84: '',
    input_85: '',
  });

  const [formError, setFormError] = useState({
    // Step 1
    input_11: '',

    // Step 2
    input_80: '',
    input_47: '',
    input_70: '',

    // Step 3
    input_54: '',

    // Step 4
    input_60: '',
    input_61: '',

    // Step 6
    input_65: '',
    input_26: '',
    input_25: '',
    input_28: '',
    input_27: '',
    input_43: '',
    input_44: '',

    input_84: '',
    input_85: '',
  });

  const { primaryMenu, aboutUsMenu, settings } = useStaticQuery(graphql`
    query wpQueryEnquiry {
      primaryMenu: wpMenu(slug: { eq: "primary-menu" }) {
        id
        name
        menuItems {
          nodes {
            id
            path
            label
            childItems {
              nodes {
                id
                path
                label
                childItems {
                  nodes {
                    id
                    path
                    label
                  }
                }
              }
            }
            parentId
          }
        }
      }
      aboutUsMenu: wpMenu(slug: { eq: "about-us-menu" }) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      settings: wp {
        fluxDnaSettings {
          fluxDna {
            email
            telephone
            headerSocialMedia {
              icon {
                altText
                mediaItemUrl
              }
              url
            }
            primaryLogo {
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  const [menuOpenStatus, setMenuOpenStatus] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var dt = new Date();
    setYear(dt.getFullYear());
  }, []);

  useEffect(() => {
    document.body.classList.remove('menu-open');
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('resize', handleChangeView);
      return () => window.removeEventListener('resize', handleChangeView);
    }
  }, []);

  const handleChangeView = () => {
    setMenuOpenStatus('');
  };
  // Sticky Header
  const handleScroll = () => {
    if (typeof window !== undefined) {
      if (window.pageYOffset > 100) {
        document.body.classList.add('header-sticky');
      }
      if (window.pageYOffset <= 100) {
        document.body.classList.remove('header-sticky');
      }
    }
  };

  const sendForm = () => {
    setIsLoading(true);
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    setFormError({
      ...formError,
      input_26: form.input_26 === '' ? 'Please enter first name.' : '',
      input_43:
        form.input_43 === '' ? 'Please select a preferred contact method.' : '',
      input_25: form.input_25 === '' ? 'Please enter last name.' : '',
      input_28: form.input_28 === '' ? 'Please enter phone number.' : '',
      input_27:
        form.input_27 === '' || !form.input_27.match(validRegex)
          ? 'Please enter valid email address.'
          : '',
      input_44: form.input_44 === '' ? 'Please enter country.' : '',
    });
    if (
      form.input_26 !== '' &&
      form.input_25 !== '' &&
      form.input_27 !== '' &&
      form.input_28 !== '' &&
      form.input_44 !== '' &&
      form.input_43 !== ''
    ) {
      document.body.classList.add('loader');

      if (form.input_80) {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        form.input_80 = form.input_80.toLocaleDateString('en-GB', options);
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      };
      const url = `${process.env.GATSBY_WP_URL}/wp-json/gf/v2/forms/${module.formShortcode}/submissions`;
      setFormError({
        ...formError,
        input_26: '',
      });

      fetch(url, requestOptions).then((response) => {
        if (response.status === 200) {
          document.body.classList.remove('loader');
          navigate('/thank-you/');
        } else {
          setStepForm(1);
          setFormError({
            ...formError,
            input_11: 'Please try again.',
          });
        }
      });
    }
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div className="enquiry-page">
      <div className="scroll-form-main">
        <div className="enquiry-header absolute z-[9999] w-full">
          <div className="container-fluid ">
            <div className="flex flex-wrap items-center justify-between">
              <Link className="navbar-brand normal-logo" to="/">
                <img
                  src={
                    settings?.fluxDnaSettings?.fluxDna?.primaryLogo
                      ?.mediaItemUrl
                  }
                  className="max-w-[156px] laptop:max-w-[156px] laptopmini:max-w-[125px]  mdscreen:max-w-[150px] smscreen2:max-w-[100px]"
                  alt={
                    settings?.fluxDnaSettings?.fluxDna?.primaryLogo
                      ?.mediaItemUrl?.altText
                  }
                />
              </Link>
              <div className="flex gap-10">
                <button
                  className="text-12 flex items-center cursor-pointer text-dark"
                  onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }}
                >
                  <p className="font-secondary text-17 text-red-300 capitalize pl-5 italic">
                    Back to site
                  </p>
                </button>
                <div
                  role="presentation"
                  onClick={() => {
                    document.body.classList.toggle('nav-menu-open');
                    setMenuOpen(!menuOpen);
                  }}
                  className={`navbar-icon flex flex-wrap lgscreen:mr-0 items-center cursor-pointer justify-end hamburger menu-open-test ${
                    menuOpen ? 'active' : ''
                  }`}
                >
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap h-screen">
          <div className="lg:w-5/12 w-full lg:order-1 order-2 mdscreen:hidden ">
            <div className="ge-img">
              <ImageOpt
                imgData={module.sideImage.mediaItemUrl}
                imgAlt={module.sideimage?.altText}
                imgClass="w-full h-screen"
                width={600}
                height={800}
              />
            </div>
          </div>
          <div className="lg:w-7/12 w-full enquiry-bg h-screen overflow-y-auto lgscreen:h-auto lg:order-2 order-1 mdscreen:pb-[250px] mdscreen:min-h-screen">
            <div className="lg:px-80 desktop2:px-50 px-30 lg:pt-[140px] pt-120 lg:pb-80 pb-40 h-full enquiry-form">
              <div className="gf-step-main border-t-2 border-r-gray-300">
                <div className={`gf-step ${'step_' + stepForm}`}></div>
              </div>
              <form className="lg:mt-30 mt-20 h-full">
                <input
                  type="hidden"
                  id="prevPage"
                  name="prevPage"
                  value={form.input_72}
                />
                <input
                  type="hidden"
                  id="referrer"
                  name="referrer"
                  value={form.input_74}
                />
                <input
                  type="hidden"
                  id="utmCampaign"
                  name="utmCampaign"
                  value={form.input_75}
                />
                <input
                  type="hidden"
                  id="utmContent"
                  name="utmContent"
                  value={form.input_76}
                />
                <input
                  type="hidden"
                  id="utmMedium"
                  name="utmMedium"
                  value={form.input_77}
                />
                <input
                  type="hidden"
                  id="utmSource"
                  name="utmSource"
                  value={form.input_78}
                />
                <input
                  type="hidden"
                  id="utmTerm"
                  name="utmTerm"
                  value={form.input_79}
                />
                <div className="form-wrapper h-full flex flex-col justify-between max-h-[480px]">
                  {stepForm === 1 && (
                    <div className="form-row">
                      <h6 className="uppercase text-black-500 text-34 xlscreen:text-26">
                        When will you be travelling?*
                      </h6>
                      <p>Let's plan your luxury African escape.</p>
                      <div className="flex flex-wrap lg:mx-minus-20 lg:mt-30 mt-15">
                        <div className="lg:w-12/12 w-full lg:px-20">
                          <div className="form-group flex gap-x-20 relative">
                            <div className="radio-bx">
                              <input
                                type="radio"
                                value="I know my exact dates"
                                checked={
                                  form.input_11 === 'I know my exact dates'
                                }
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_11: e.target.value,
                                    input_84: '',
                                    input_85: '',
                                  });
                                  setFormError({
                                    ...formError,
                                    input_11: '',
                                  });
                                }}
                                id="I know my exact dates"
                              />
                              <label
                                htmlFor="I know my exact dates"
                                className="text-dark font-400 text-16"
                              >
                                <h4> I know my exact dates </h4>
                              </label>
                            </div>
                            <div className="radio-bx">
                              <input
                                type="radio"
                                value="Not sure of my exact dates"
                                checked={
                                  form.input_11 === 'Not sure of my exact dates'
                                }
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_11: e.target.value,
                                    input_80: '',
                                    input_82: '',
                                  });
                                  setFormError({
                                    ...formError,
                                    input_11: '',
                                  });
                                }}
                                id="Not sure of my exact dates"
                              />
                              <label
                                htmlFor="Not sure of my exact dates"
                                className="text-dark font-400 text-16"
                              >
                                <h4> Not sure of my exact dates </h4>
                              </label>
                            </div>
                          </div>
                          {form.input_11 === 'I know my exact dates' ? (
                            <div className="flex flex-wrap lg:mx-minus-20 lg:mt-30 mt-15 gap-y-5">
                              <div className="lg:w-6/12 w-full lg:px-20 flex items-end">
                                <div className="form-group flex flex-wrap w-full">
                                  <label
                                    className="w-full"
                                    htmlFor="Arrival date"
                                  >
                                    <span className="text-12 text-black-500 mb-5 block uppercase">
                                      Arrival date*
                                    </span>
                                    <DatePicker
                                      wrapperClassName="datePicker"
                                      className="enquiry-date-picker !w-full !h-[45px] placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                                      selected={form.input_80}
                                      placeholderText="Select Date"
                                      dateFormat="dd MMMM yyyy"
                                      minDate={new Date()}
                                      onChange={(date) => {
                                        setForm({
                                          ...form,
                                          input_80: date,
                                        });

                                        date.setDate(date.getDate());
                                        setFormError({
                                          ...formError,
                                          input_80: '',
                                        });
                                      }}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="lg:w-6/12 w-full lg:px-20 flex items-end">
                                <div className="form-group flex flex-wrap w-full">
                                  <label
                                    className="w-full"
                                    htmlFor="Arrival date"
                                  >
                                    <span className="text-12 text-black-500 mb-5 block uppercase">
                                      Departure date*
                                    </span>
                                    <DatePicker
                                      wrapperClassName="datePicker"
                                      className="enquiry-date-picker !w-full !h-[45px] placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                                      selected={form.input_82}
                                      placeholderText="Select Date"
                                      dateFormat="dd MMMM yyyy"
                                      minDate={new Date()}
                                      onChange={(date) => {
                                        setForm({
                                          ...form,
                                          input_82: date,
                                        });

                                        date.setDate(date.getDate());
                                        setFormError({
                                          ...formError,
                                          input_82: '',
                                        });
                                      }}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex flex-wrap lg:mx-minus-20 lg:mt-30 mt-15 gap-y-5">
                              <div className="lg:w-6/12 w-full lg:px-20">
                                <div className="form-group flex flex-wrap">
                                  <label
                                    className="w-full  text-15"
                                    htmlFor="Month"
                                  >
                                    <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                      Month*
                                    </span>
                                  </label>
                                  <select
                                    name=""
                                    id=""
                                    value={form.input_84}
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_84: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_84: '',
                                      });
                                    }}
                                  >
                                    <option value="">Select month</option>
                                    <option value="undecided">
                                      I'm undecided
                                    </option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                  </select>
                                </div>
                                {formError.input_85 && (
                                  <div className="error">
                                    <span className="text-red">
                                      {formError.input_85}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="lg:w-6/12 w-full lg:px-20">
                                <div className="form-group flex flex-wrap">
                                  <label
                                    className="w-full  text-15"
                                    htmlFor="Year"
                                  >
                                    <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                      Year*
                                    </span>
                                  </label>
                                  <select
                                    name=""
                                    value={form.input_85}
                                    id=""
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        input_85: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_85: '',
                                      });
                                    }}
                                  >
                                    <option value="">Select year</option>
                                    <option value="undecided">
                                      I'm undecided
                                    </option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                  </select>
                                </div>
                                {formError.input_85 && (
                                  <div className="error">
                                    <span className="text-red">
                                      {formError.input_85}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {formError.input_82 && (
                            <div className="error mt-20">
                              <span className="text-red">
                                {formError.input_82}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {stepForm === 2 && (
                    <div className="form-row">
                      <div className="flex justify-between items-end mdscreen:flex-col mdscreen:items-start">
                        <div className="left-info">
                          <h6 className="uppercase text-black-500 text-34 xlscreen:text-26">
                            What is your trip budget?*
                          </h6>
                          <p>
                            Your budget should include your accommodation,
                            transport, guides and domestic flights. Everything
                            except your international flights. Most safari
                            lodges are fully inclusive (food, drinks &
                            experiences).
                          </p>
                          <br />
                          <p>
                            To help plan your budget accurately, please see our{' '}
                            <a
                              href="/images/Timeless-Hotel-Lodge-Price-Comparison.pdf"
                              download
                              class="text-red-400"
                            >
                              Timeless Hotel & Lodge rates document here
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap lg:mx-minus-20 lg:mt-30 mt-15">
                        <div className="lg:w-12/12 w-full lg:px-20">
                          <div className="select-price mb-10">
                            <select
                              name=""
                              id="currency"
                              onChange={(e) => {
                                setSelectedBudgetCurrency(e.target.value);
                                setForm({
                                  ...form,
                                  input_67: e.target.value,
                                });
                              }}
                            >
                              {Object.keys(CURRENCIES).map((currency) => (
                                <option value={currency} key={currency}>
                                  {CURRENCIES[currency]}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group grid grid-cols-1 mdscreen:grid-cols-1  gap-x-20 gap-y-3 relative ">
                            {budgetOptions[selectedBudgetCurrency].options.map(
                              (item, index) => (
                                <div
                                  className="radio-bx "
                                  key={index.toString()}
                                >
                                  <input
                                    type="radio"
                                    value={item.value}
                                    checked={
                                      form[
                                        budgetOptions[selectedBudgetCurrency]
                                          .inputId
                                      ] === item.value
                                    }
                                    onChange={(e) => {
                                      setForm({
                                        ...form,
                                        [budgetOptions[selectedBudgetCurrency]
                                          .inputId]: e.target.value,
                                      });
                                      setFormError({
                                        ...formError,
                                        input_63: '',
                                      });
                                    }}
                                    name={`${selectedBudgetCurrency}-budget`}
                                    id={item.value}
                                  />
                                  <label
                                    htmlFor={item.value}
                                    className="text-dark font-400 text-16"
                                  >
                                    <h4> {parse(item.label)} </h4>
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                          {formError.input_63 && (
                            <div className="error mt-20">
                              <span className="text-red">
                                {formError.input_63}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {stepForm === 3 && (
                    <div className="form-row">
                      <h6 className=" lg:text-34 xlscreen:text-26 text-20">
                        Where would you like to go?*
                      </h6>
                      <p>Let's plan your African escape.</p>
                      <div className="flex flex-wrap lg:mx-minus-20 lg:mt-30 mt-15 gap-y-3">
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Botswana"
                              checked={form.input_54_1 === 'Botswana'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_1:
                                    e.target.value === form.input_54_1
                                      ? ''
                                      : 'Botswana',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_1: '',
                                });
                              }}
                              id="Botswana"
                            />
                            <label
                              htmlFor="Botswana"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Botswana </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Kenya"
                              checked={form.input_54_5 === 'Kenya'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_5:
                                    e.target.value === form.input_54_5
                                      ? ''
                                      : 'Kenya',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_5: '',
                                });
                              }}
                              id="Kenya"
                            />
                            <label
                              htmlFor="Kenya"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Kenya </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Madagascar"
                              checked={form.input_54_3 === 'Madagascar'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_3:
                                    e.target.value === form.input_54_3
                                      ? ''
                                      : 'Madagascar',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_3: '',
                                });
                              }}
                              id="Madagascar"
                            />
                            <label
                              htmlFor="Madagascar"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Madagascar </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx">
                            <input
                              type="checkbox"
                              value="Mauritius"
                              checked={form.input_54_6 === 'Mauritius'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_6:
                                    e.target.value === form.input_54_6
                                      ? ''
                                      : 'Mauritius',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_6: '',
                                });
                              }}
                              id="Mauritius"
                            />
                            <label
                              htmlFor="Mauritius"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Mauritius </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Mozambique"
                              checked={form.input_54_4 === 'Mozambique'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_4:
                                    e.target.value === form.input_54_4
                                      ? ''
                                      : 'Mozambique',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_4: '',
                                });
                              }}
                              id="Mozambique"
                            />
                            <label
                              htmlFor="Mozambique"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Mozambique </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Namibia"
                              checked={form.input_54_7 === 'Namibia'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_7:
                                    e.target.value === form.input_54_7
                                      ? ''
                                      : 'Namibia',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_7: '',
                                });
                              }}
                              id="Namibia"
                            />
                            <label
                              htmlFor="Namibia"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Namibia </h4>
                            </label>
                          </div>
                        </div>
                        <div className="lg:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Republic of Congo"
                              checked={form.input_54_14 === 'Republic of Congo'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_14:
                                    e.target.value === form.input_54_14
                                      ? ''
                                      : 'Republic of Congo',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_14: '',
                                });
                              }}
                              id="Republic of Congo"
                            />
                            <label
                              htmlFor="Republic of Congo"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Republic of Congo </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Rwanda"
                              checked={form.input_54_2 === 'Rwanda'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_2:
                                    e.target.value === form.input_54_2
                                      ? ''
                                      : 'Rwanda',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_2: '',
                                });
                              }}
                              id="Rwanda"
                            />
                            <label
                              htmlFor="Rwanda"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Rwanda </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Seychelles"
                              checked={form.input_54_9 === 'Seychelles'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_9:
                                    e.target.value === form.input_54_9
                                      ? ''
                                      : 'Seychelles',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_9: '',
                                });
                              }}
                              id="Seychelles"
                            />
                            <label
                              htmlFor="Seychelles"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Seychelles </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="South Africa"
                              checked={form.input_54_10 === 'South Africa'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_10:
                                    e.target.value === form.input_54_10
                                      ? ''
                                      : 'South Africa',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_10: '',
                                });
                              }}
                              id="South Africa"
                            />
                            <label
                              htmlFor="South Africa"
                              className="text-dark font-400 text-16"
                            >
                              <h4> South Africa </h4>
                            </label>
                          </div>
                        </div>
                        <div className="md:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Tanzania"
                              checked={form.input_54_8 === 'Tanzania'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_8:
                                    e.target.value === form.input_54_8
                                      ? ''
                                      : 'Tanzania',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_8: '',
                                });
                              }}
                              id="Tanzania"
                            />
                            <label
                              htmlFor="Tanzania"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Tanzania </h4>
                            </label>
                          </div>
                        </div>
                        <div className="lg:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Uganda"
                              checked={form.input_54_11 === 'Uganda'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_11:
                                    e.target.value === form.input_54_11
                                      ? ''
                                      : 'Uganda',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_11: '',
                                });
                              }}
                              id="Uganda"
                            />
                            <label
                              htmlFor="Uganda"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Uganda </h4>
                            </label>
                          </div>
                        </div>
                        <div className="lg:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Zambia"
                              checked={form.input_54_13 === 'Zambia'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_13:
                                    e.target.value === form.input_54_13
                                      ? ''
                                      : 'Zambia',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_13: '',
                                });
                              }}
                              id="Zambia"
                            />
                            <label
                              htmlFor="Zambia"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Zambia </h4>
                            </label>
                          </div>
                        </div>
                        <div className="lg:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Zanzibar"
                              checked={form.input_54_12 === 'Zanzibar'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_12:
                                    e.target.value === form.input_54_12
                                      ? ''
                                      : 'Zanzibar',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_12: '',
                                });
                              }}
                              id="Zanzibar"
                            />
                            <label
                              htmlFor="Zanzibar"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Zanzibar </h4>
                            </label>
                          </div>
                        </div>
                        <div className="lg:w-4/12 w-full lg:px-20">
                          <div className="chkbox-bx radio-bx ">
                            <input
                              type="checkbox"
                              value="Zimbabwe"
                              checked={form.input_54_15 === 'Zimbabwe'}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_54_15:
                                    e.target.value === form.input_54_15
                                      ? ''
                                      : 'Zimbabwe',
                                  input_54: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_54_15: '',
                                });
                              }}
                              id="Zimbabwe"
                            />
                            <label
                              htmlFor="Zimbabwe"
                              className="text-dark font-400 text-16"
                            >
                              <h4> Zimbabwe </h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {stepForm === 4 && (
                    <div className="form-row">
                      <h6 className=" lg:text-34 xlscreen:text-26 text-20">
                        Who will you be travelling with?
                      </h6>
                      <p>Let's plan your African escape.</p>
                      <div className="flex flex-wrap lg:mx-minus-20">
                        <div className="lg:w-6/12 w-full lg:px-20">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full  text-15"
                              htmlFor="Number of Adults"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Number of Adults*
                              </span>
                            </label>
                            <select
                              name=""
                              id=""
                              value={form.input_60}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_60: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_60: '',
                                });
                              }}
                            >
                              <option value="">Select number...</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>

                          {formError.input_60 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_60}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="lg:w-6/12 w-full lg:px-20">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full  text-15"
                              htmlFor="Number Of Children"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Number Of Children*
                              </span>
                            </label>
                            <select
                              name=""
                              value={form.input_61}
                              id=""
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_61: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_61: '',
                                });
                              }}
                            >
                              <option value="">Select number...</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                            <p className="text-12 mt-15">
                              *Children are considered under the age of 15
                            </p>
                          </div>
                          {formError.input_61 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_61}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {stepForm === 5 && (
                    <div className="form-row">
                      <h6 className=" lg:text-34 xlscreen:text-26 text-20">
                        Let’s stay in touch?*
                      </h6>
                      <p>Let's plan your African escape.</p>
                      <div className="flex flex-wrap lg:mx-minus-20 ">
                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full text-6"
                              htmlFor="First Name"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                First Name*
                              </span>
                            </label>
                            <input
                              type="text"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_26}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_26: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_26: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>

                          {formError.input_26 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_26}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full text-6"
                              htmlFor="Last name"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Last name*
                              </span>
                            </label>
                            <input
                              type="text"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_25}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_25: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_25: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>
                          {formError.input_25 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_25}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full text-6"
                              htmlFor="Phone Number"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Phone Number*
                              </span>
                            </label>
                            <input
                              type="text"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_28}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_28: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_28: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>
                          {formError.input_28 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_28}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="lg:w-6/12 w-full lg:px-20 arrownone">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full text-6"
                              htmlFor="Email Address"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Email Address*
                              </span>
                            </label>
                            <input
                              type="text"
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_27}
                              onBlur={(e) => {
                                var value = e.target.value;
                                var pattern =
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                if (!pattern.test(value)) {
                                  setForm({ ...form, input_27: '' });
                                  setFormError({
                                    ...formError,
                                    input_27: 'Please enter valid email.',
                                  });
                                }
                              }}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_27: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_27: '',
                                });
                              }}
                              placeholder="Type here..."
                            />
                          </div>
                          {formError.input_27 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_27}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="lg:w-6/12 w-full lg:px-20">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full text-6"
                              htmlFor="Country travelling from"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Country travelling from*
                              </span>
                            </label>
                            <select
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_44}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_44: e.target.value,
                                });
                                setFormError({
                                  ...formError,
                                  input_44: '',
                                });
                              }}
                            >
                              <option value=" ">Select Country</option>
                              {countries.map((country) => (
                                <option key={country.code} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {formError.input_44 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_44}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="lg:w-6/12 w-full lg:px-20">
                          <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                            <label
                              className="w-full text-6"
                              htmlFor="Preferred method of contact"
                            >
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Preferred method of contact*
                              </span>
                            </label>
                            <select
                              className="placeholder:text-black-500 placeholder:text-14 placeholder:relative placeholder:top-[-2px]"
                              value={form.input_43}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  input_43: e.target.value,
                                });
                              }}
                            >
                              <option value=" ">Select Option...</option>
                              <option value="WhatsApp">WhatsApp</option>
                              <option value="Phone">Phone</option>
                              <option value="Email">Email</option>
                            </select>
                          </div>
                          {formError.input_43 && (
                            <div className="error">
                              <span className="text-red">
                                {formError.input_43}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {stepForm === 6 && (
                    <div className="form-row">
                      <h6 className="uppercase text-black-500 text-34 xlscreen:text-26">
                        Tell us more about your trip
                      </h6>
                      <p>
                        More info about your trip / important personal info /
                        your personal interests & passions etc.
                      </p>
                      <div className="flex flex-wrap lg:mx-minus-20 lg:mt-30 mt-15">
                        <div className="lg:w-12/12 w-full lg:px-20">
                          <div className="form-group grid grid-cols-1 mdscreen:grid-cols-1  gap-x-20 gap-y-2 relative overflow-hidden">
                            <div className="">
                              <span className="text-12 text-black-500 mb-5 inline-block uppercase">
                                Message
                              </span>
                              <textarea
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    input_65: e.target.value,
                                  });
                                  setFormError({
                                    ...formError,
                                    input_65: '',
                                  });
                                }}
                                id="message"
                                value={form.input_65}
                              ></textarea>
                            </div>
                          </div>
                          {formError.input_65 && (
                            <div className="error mt-20">
                              <span className="text-red">
                                {formError.input_65}
                              </span>
                            </div>
                          )}
                          <div className="radio-bx">
                            <input
                              type="checkbox"
                              value="Yes"
                              checked={form.input_81_1 === 'Yes'}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setForm({
                                  ...form,
                                  input_81_1: checked ? 'Yes' : '',
                                });
                              }}
                              id="input_81_1"
                            />
                            <label
                              htmlFor="input_81_1"
                              className="text-dark font-400 text-16"
                            >
                              <h4>
                                I would like to receive the Timeless Africa
                                Safaris newsletter.
                              </h4>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={`form-btn flex items-center my-20 ${
                      stepForm === 1 ? 'justify-end' : 'justify-between'
                    }`}
                  >
                    {stepForm > 1 && (
                      <a
                        href="#"
                        onClick={() => {
                          setStepForm((stepForm) => stepForm - 1);
                        }}
                        className="button btn-red-border cursor-pointer"
                      >
                        Previous Step
                      </a>
                    )}
                    {stepForm !== 6 ? (
                      <a
                        onClick={() => {
                          if (
                            stepForm === 1 &&
                            ((form?.input_80 === '' && form?.input_82 === '') ||
                              (form?.input_84 === '' && form?.input_85 == ''))
                          ) {
                            setFormError({
                              ...formError,
                              input_82:
                                form?.input_82 === ''
                                  ? 'Please fill out required fields.'
                                  : '',
                            });

                            if (
                              (form?.input_80 !== '' &&
                                form?.input_82 !== '') ||
                              (form?.input_84 !== '' && form?.input_85 !== '')
                            ) {
                              document.body.classList.add('loader');
                              setStepForm((stepForm) => stepForm + 1);
                              document.body.classList.remove('loader');
                            }
                          } else if (stepForm === 2) {
                            setFormError({
                              ...formError,
                              input_63:
                                form.input_63 === '' &&
                                form.input_68 === '' &&
                                form.input_69 === ''
                                  ? 'Please select a budget.'
                                  : '',
                            });

                            if (
                              form.input_63 !== '' ||
                              form.input_68 !== '' ||
                              form.input_69 !== ''
                            ) {
                              document.body.classList.add('loader');
                              setStepForm((stepForm) => stepForm + 1);
                              document.body.classList.remove('loader');
                            }
                          } else if (stepForm === 3) {
                            setFormError({
                              ...formError,
                              input_54:
                                form.input_54 === ''
                                  ? 'Please select location.'
                                  : '',
                            });
                            if (form?.input_54 !== '') {
                              document.body.classList.add('loader');
                              setStepForm((stepForm) => stepForm + 1);
                              document.body.classList.remove('loader');
                            }
                          } else if (stepForm === 4) {
                            setFormError({
                              ...formError,
                              input_60:
                                form?.input_60 === ''
                                  ? 'Please select adults.'
                                  : '',
                              input_61:
                                form?.input_61 === ''
                                  ? 'Please select children.'
                                  : '',
                            });
                            if (
                              form?.input_60 !== '' &&
                              form?.input_61 !== ''
                            ) {
                              document.body.classList.add('loader');
                              setStepForm((stepForm) => stepForm + 1);
                              document.body.classList.remove('loader');
                            }
                          } else if (stepForm === 5) {
                            setFormError({
                              ...formError,
                              input_26:
                                form.input_26 === ''
                                  ? 'Please enter first name.'
                                  : '',
                              input_43:
                                form.input_43 === ''
                                  ? 'Please select a preferred contact method.'
                                  : '',
                              input_25:
                                form.input_25 === ''
                                  ? 'Please enter last name.'
                                  : '',
                              input_28:
                                form.input_28 === ''
                                  ? 'Please enter phone number.'
                                  : '',
                              input_27:
                                form.input_27 === '' ||
                                !form.input_27.match(
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                )
                                  ? 'Please enter valid email address.'
                                  : '',
                              input_44:
                                form.input_44 === ''
                                  ? 'Please enter country.'
                                  : '',
                            });

                            if (
                              form.input_26 !== '' &&
                              form.input_25 !== '' &&
                              form.input_27 !== '' &&
                              form.input_28 !== '' &&
                              form.input_44 !== '' &&
                              form.input_43 !== ''
                            ) {
                              document.body.classList.add('loader');
                              setStepForm((stepForm) => stepForm + 1);
                              document.body.classList.remove('loader');
                            }
                          }
                        }}
                        className="button btn-border cursor-pointer"
                      >
                        <span>Continue</span>
                        <img src="/images/btn-red-bg-small.png" alt="" />
                      </a>
                    ) : (
                      <a
                        href="#"
                        onClick={sendForm}
                        className="button header-btn-red cursor-pointer"
                      >
                        <span> {isLoading ? 'Loading' : 'Send Enquiry'} </span>
                      </a>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="main-nav nav-container">
          <div className="mainNavimg relative mdscreen:hidden">
            <img src="/images/menu-img.webp" className="mainNavbgImg" alt="" />
            <div className="menu-overlay absolute top-0 left-0 w-full h-full !hidden">
              <img src="/images/menu-open-bg.png" alt="menu-bg" />
            </div>
          </div>

          <div className="mainNav__wrap relative m-auto p-menu_mobile z-9 h-full">
            <div className="container-fluid h-full">
              <div className="navbarMoile w-full h-full">
                <div className="navbarMoile-inner flex items-end justify-between min-h-full mobile-menu pb-200 lgscreen:items-start lgscreen:pb-0 lgscreen:pt-100 mdscreen:pb-0 mdscreen:items-center mdscreen:flex-col mdscreen:w-full mdscreen:pr-[18vw] mdscreen:pt-0 mdscreen:justify-center mdscreen:inline-block">
                  <div className="md:w-5/12 mdscreen:w-full desktop2:w-[50%]">
                    <div className="mainNavGrid">
                      <div className="mainNav__col navMenu text-left mdscreen:text-left relative mainNav__col--left flex flex-col items-start mdscreen:items-start pr-60 mdscreen:pr-0">
                        <ul className="navMenuLeft topMenu visible list-none m-0 p-0 space-y-3 w-full">
                          {primaryMenu?.menuItems?.nodes
                            ?.filter((menuItem) => menuItem?.parentId === null)
                            .map((menuItem) => (
                              <li
                                key={menuItem.id}
                                className="sideMenuhoverable w-full"
                              >
                                <Link
                                  className="inline-block"
                                  to={menuItem.path}
                                  onMouseEnter={() => {
                                    setMenuOpenStatus(menuItem.id);
                                  }}
                                >
                                  {menuItem.label}
                                </Link>
                                {menuItem.childItems?.nodes?.length > 0 && (
                                  <span
                                    role="presentation"
                                    className={`master-menu ${
                                      menuItem.label !== 'Destinations'
                                        ? 'hidden'
                                        : ''
                                    } ${
                                      menuOpenStatus === menuItem.id
                                        ? 'menu_open_arrow'
                                        : ''
                                    }`}
                                    onClick={() => {
                                      setMenuOpenStatus((menuOpenStatus) =>
                                        menuOpenStatus === menuItem.id
                                          ? ''
                                          : menuItem.id
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                  </span>
                                )}
                                {menuOpenStatus === menuItem.id &&
                                  menuItem.childItems?.nodes?.length > 0 &&
                                  menuItem.label === 'Destinations' && (
                                    <div
                                      id="destination"
                                      className="p-6 mega-menu mb-16 sm:mb-0 bg-teal-700 lgscreen:!mt-10"
                                    >
                                      <div
                                        className={`mega-menu-grid flex flex-col ${
                                          menuItem.label === 'Destinations'
                                            ? 'lg:mt-[-10px]'
                                            : ''
                                        } ${
                                          menuItem.label === 'Experiences'
                                            ? 'lg:mt-[30px]'
                                            : ''
                                        } ${
                                          menuItem.label === 'Travel Styles'
                                            ? 'lg:mt-[75px]'
                                            : ''
                                        }`}
                                      >
                                        <ul className="space-y-2">
                                          {menuItem?.childItems?.nodes?.map(
                                            (childItem) => (
                                              <li key={childItem.id}>
                                                <Link to={childItem.path}>
                                                  {childItem.label}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                        <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 mt-30 lgscreen:justify-start lgscreen:mt-15 lgscreen:mb-10">
                                          <Link
                                            to={menuItem.path}
                                            className="button btn-transparent btn-transparent--mobile"
                                          >
                                            <span className="relative z-[10] !px-0 !text-white">
                                              View All {menuItem.label}
                                            </span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="btn-custom mx-0 mt-60 mdscreen:mt-30 mb-20 hidden mdscreen:block">
                    <Link
                      to="/guest-enquiry/"
                      className="button header-btn-red"
                    >
                      Let’s Start Planning
                    </Link>
                  </div>
                  <div className="md:w-5/12 w-full justify-center ipad:w-50-per mdscreen:w-full">
                    <div className="flex justify-center gap-x-20 ipad:gap-x-6 lgscreen:gap-x-10 mdscreen:justify-start mdscreen:pt-0 mdscreen:flex-col mdscreen:gap-y-8 mdscreen:pl-5">
                      {aboutUsMenu?.menuItems?.nodes?.length > 0 && (
                        <div className="mainNav__col--right">
                          <h6 className="uppercase text-19 text-black-400 pb-10 mdscreen:pb-0">
                            <Link to={aboutUsMenu?.menuItems?.nodes?.[0]?.path}>
                              {aboutUsMenu?.menuItems?.nodes?.[0]?.label}
                            </Link>
                          </h6>
                          <ul>
                            {aboutUsMenu?.menuItems?.nodes?.length > 0 &&
                              aboutUsMenu?.menuItems?.nodes
                                ?.slice(1)
                                .map((item) => (
                                  <li key={item?.id}>
                                    <Link to={item?.path}>{item?.label}</Link>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      )}
                      <div className="mainNav__col--right mdscreen:pb-100">
                        <h6 className="uppercase text-19  text-black-400 pb-10 mdscreen:pb-0">
                          Contact Us
                        </h6>
                        <div className="content">
                          {settings?.fluxDnaSettings?.fluxDna?.telephone && (
                            <p>
                              <a href="tel:+27 21 461 9001">
                                T:
                                {settings?.fluxDnaSettings?.fluxDna?.telephone}
                              </a>
                            </p>
                          )}
                          {settings?.fluxDnaSettings?.fluxDna?.email && (
                            <p>
                              <a
                                href={`mailto:${settings?.fluxDnaSettings?.fluxDna?.email}`}
                              >
                                E: {settings?.fluxDnaSettings?.fluxDna?.email}
                              </a>
                            </p>
                          )}
                        </div>
                        <div className="sicon">
                          <ul className="flex mt-15 space-x-5">
                            {settings?.fluxDnaSettings?.fluxDna?.headerSocialMedia?.map(
                              (item, index) => (
                                <li key={index.toString()}>
                                  <Link to={item?.url || '#'} target="_blank">
                                    <img
                                      src={item?.icon?.mediaItemUrl}
                                      alt={item?.icon?.altText}
                                    />
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                C
              </div>
            </div>

            <div className="menu-overlay absolute top-0 right-0 w-[45%] lgscreen:w-50-per ipad:w-[55%] mdscreen:w-full h-full -z-99">
              <img
                src="/images/menu-open-bg-enquiry.webp"
                alt="menu-bg"
                className="h-full object-cover w-full"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuestEnquiry;
export const GuestEnquiryFragment = graphql`
  fragment GuestEnquiryFragment on WpPage_Pagecontent_PageContent_GuestEnquiry {
    formShortcode
    sideImage {
      altText
      mediaItemUrl
    }
  }
`;
